import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {
  increment,
  decrement,
  removeFromCart,
  clearCart,
} from "../redux/cart/action";

const ShoppingCart = () => {
  const { cart } = useSelector((state) => state.shoppingCart);
  const dispatch = useDispatch();

  const handleIncrement = (productId) => {
    dispatch(increment(productId));
    Swal.fire({
      title: "Cart Updated",
      icon: "success",
      showConfirmButton: false,
      timerProgressBar: true,
      timer: 2000,
      toast: true,
      position: "top",
    });
  };

  const handleDecrement = (productId) => {
    dispatch(decrement(productId));
    Swal.fire({
      title: "Cart Updated",
      icon: "success",
      showConfirmButton: false,
      timerProgressBar: true,
      timer: 2000,
      toast: true,
      position: "top",
    });
  };

  const handleRemoveFromCart = (productId) => {
    dispatch(removeFromCart(productId));
    Swal.fire({
      title: "Cart Updated",
      icon: "success",
      showConfirmButton: false,
      timerProgressBar: true,
      timer: 2000,
      toast: true,
      position: "top",
    });
  };

  const handleClearCart = () => {
    dispatch(clearCart());
    Swal.fire({
      title: "Cart Empty",
      icon: "danger",
      showConfirmButton: false,
      timerProgressBar: true,
      timer: 2000,
      toast: true,
      position: "top",
    });
  };

  return (
    <>
      {cart.length === 0 ? (
        <div className="col-md-12 text-center">
          <div>
            <i className="bi bi-basket-fill" style={{ fontSize: "100px" }}></i>
          </div>
          <h3 className="text-bold">Cart is empty</h3>
          <Link className="btn btn-outline-dark mt-3" to="/shop">
            Shop
          </Link>
        </div>
      ) : (
        <div className="cart">
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-12">
              {cart &&
                cart.map((product) => (
                  <div className="container" key={product.id}>
                    <div className="row border border-top-0 border-end-0 border-start-0  text-center d-flex justify-content-center align-items-center">
                      <div className="col-10">
                        <div className="card border-0 mb-1">
                          <div className="card-body p-4">
                            <div className="row d-flex justify-content-between align-items-center">
                              <div className="col-md-2 col-lg-2 col-xl-2">
                                <img
                                  src={product.image}
                                  className="img-fluid rounded-3"
                                  alt={product.id}
                                />
                              </div>
                              <div className="col-md-3 col-lg-3 col-xl-3">
                                <p className="lead fw-normal mb-2">
                                  {product.title}
                                </p>
                                <p className="lead text-muted mb-2">
                                  {product.category}
                                </p>
                              </div>
                              <div className="col-md-3 col-lg-3 col-xl-2 d-flex my-2 justify-content-center">
                                <button
                                  onClick={() => handleIncrement(product.id)}
                                  className="btn btn-sm btn-dark me-2"
                                >
                                  +
                                </button>
                                <span>{product.qty}</span>
                                <button
                                  onClick={() => handleDecrement(product.id)}
                                  className="btn btn-sm btn-dark ms-2"
                                >
                                  -
                                </button>
                              </div>
                              <div className="col-md-3 col-lg-2 mb-2 col-xl-2 offset-lg-1">
                                <h5 className="mb-0">{product.price}$</h5>
                              </div>
                              <div className="col-md-1 col-lg-1 col-xl-1">
                                <button
                                  onClick={() =>
                                    handleRemoveFromCart(product.id)
                                  }
                                  className="btn btn-danger btn-sm"
                                >
                                  <i className="bi bi-trash3"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              <div className="container">
                <div className="row d-flex text-center justify-content-center align-items-center">
                  <div className="col-10">
                    <div className="card border-0 mb-1">
                      <div className="card-body p-4">
                        <div className="row d-flex justify-content-between align-items-center">
                          <div className="col-md-2 col-lg-2 col-xl-2">
                            <button
                              onClick={() => handleClearCart()}
                              className="btn btn-dark"
                            >
                              Clear Cart
                            </button>
                          </div>
                          <div className="col-md-3 col-lg-3 col-xl-3"></div>
                          <div className="col-md-3 col-lg-3 col-xl-2 d-flex justify-content-center"></div>
                          <div className="col-md-3 col-lg-2 col-xl-2 offset-lg-1 my-3">
                            <strong>
                              Total :{" "}
                              {cart.reduce((total, product) => {
                                return total + product.price * product.qty;
                              }, 0)}
                            </strong>
                          </div>
                          <div className="col-md-1 col-lg-1 col-xl-1 mb-2">
                            <a href="/" className="btn btn-success btn-block">
                              Checkout
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      )}
    </>
  );
};

export default ShoppingCart;
