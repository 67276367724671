const Home = () => {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-12">
          <h1>Shopping Cart</h1>
          <p className="mt-2">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut nulla
            blanditiis harum libero veniam iusto sit eius adipisci. Esse
            voluptate dolor veniam sapiente similique quas exercitationem libero
            id tempora voluptatibus iure qui, dicta cum. Sit, pariatur rerum
            alias atque quam cupiditate similique tempora ullam sed rem
            distinctio dignissimos, incidunt laboriosam accusantium? Voluptas
            corporis minima magnam nesciunt delectus nulla reprehenderit iure
            tempora totam earum vitae in eum quam et, perspiciatis, unde maxime!
            Ad nobis quas iusto ipsa adipisci autem iure et quod odit repellat
            recusandae vero, expedita, laudantium praesentium magni consectetur
            assumenda, quidem cupiditate! Natus consequatur facilis vel
            similique perspiciatis a?
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
