import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { addToCart } from "../redux/cart/action";
import axios from "axios";

const Products = () => {
  const [products, setProducts] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
    Swal.fire({
      title: "Product added",
      icon: "success",
      showConfirmButton: false,
      timerProgressBar: true,
      timer: 2000,
      toast: true,
      position: "top",
    });
  };

  useEffect(() => {
    const getdata = async () => {
      await axios
        .get("https://fakestoreapi.com/products")
        .then((resposne) => {
          setProducts(resposne.data);
          setLoading(false);
          setError(null);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.message,
          });
          setError(err.message);
          setLoading(false);
          setProducts(null);
        });
    };

    getdata();
  }, []);

  return (
    <div className="container">
      <div className="row my-5 g-3">
        <div className="text-center text-danger">
        {error && <div>{error}</div>}
        {loading && <div className="spinner-border text-primary"></div>}
        </div>
        {products &&
          products.map((product) => (
            <div className="col-md-3" key={product.id}>
              <div className="card product p-2">
                <img
                  className="card-img-top product-img"
                  src={product.image}
                  alt={product.id}
                />
                <div className="card-body">
                  <h5 className="card-title product-title">{product.title}</h5>
                  <p className="card-text">{product.category}</p>
                </div>
                <div className="card-footer d-flex justify-content-between">
                  <button
                    onClick={() => handleAddToCart(product)}
                    className="btn btn-primary"
                  >
                    Add to cart
                  </button>
                  <span className="fw-bold">{product.price}<span className="text-danger">$</span></span>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Products;
