import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import logo from "../logo.png";

const Header = () => {
    const { cart } = useSelector((state => state.shoppingCart));

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/"><img className="logo" src={logo} alt="logo" /></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbar">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink className={(navData) => navData.isActive ? 'nav-link active' : 'nav-link'} to='/' >Home</NavLink >
                        </li>
                        <li className="nav-item">
                            <NavLink className={(navData) => navData.isActive ? 'nav-link active' : 'nav-link'} to='/shop'>Shop</NavLink>
                        </li>
                    </ul>
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <NavLink className={(navData) => navData.isActive ? 'nav-link active' : 'nav-link '} to="/cart">
                                <i className="bi bi-cart4 fs-3"></i>
                                <span className="position-absolute  start-75 translate-middle badge rounded-pill bg-danger">{cart.length}</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Header;